<template>
    <div class="upUserInfo">
        <div style="height:0.6rem"></div>
        <div class="usetInfoModule" v-for="(item,index) in userData" :key="index">
            <div class="userModuleTop">{{moduleName(index)}}</div>
            <div class="userValueModule">
                <div class="userValueLable">
                    <div class="userValueName"><div>姓</div><div>名</div></div>
                </div>
                <div class="userValue">
                    <input type="text" class="inputUserName" v-model="item.name">
                </div>
            </div>
            <div class="userValueModule">
                <div class="userValueLable">
                    <div class="userValueName"><div>身</div><div>份</div><div>证</div></div>
                </div>
                <div class="userValue">
                    <input type="text" class="inputUserName" v-model="item.idCardNo">
                </div>
            </div>
            <div class="userValueModule">
                <div class="userValueLable">
                    <div class="userValueName"><div>手</div><div>机</div><div>号</div></div>
                </div>
                <div class="userValue">
                    <input type="text" class="inputUserName" v-model="item.mobile">
                </div>
            </div>
        </div>
        <div class="addModule">
            <div class="add" @click="addUserValue">添加</div>
        </div>
        <div style="height:1.3rem"></div>
        <div class="userInfoBtnModule">
            <div class="saveUserValue" @click="upUserData">提交信息</div>
        </div>
    </div>
</template>
<style scoped>
.upUserInfo{
    width: 7.5rem;
    min-height: 100vh;
    background: #f8f8f8;
}
.usetInfoModule{
    width: 6.9rem;
    margin: 0 auto;
    border-radius: 0.1rem;
    padding-bottom: 0.15rem;
    margin-bottom: 0.4rem;
    background: #ffffff;
}
.userModuleTop{
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.32rem;
    color: #050505;
    text-indent: 0.38rem;
    border-bottom: 0.01rem solid #dbdbdb;
}
.userValueModule{
    width: 100%;
    height: 0.8rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}
.userValueLable{
    width: 1.2rem;
    height: 0.8rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    font-size: 0.28rem;
    color: #333333;
}
.userValueName{
    width: 0.9rem;
    height: 0.8rem;
    font-size: 0.28rem;
    color: #333333;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
}
.userValue{
    width: 5.4rem;
    height: 0.8rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 0.01rem solid #dbdbdb;
}
.inputUserName{
    width: 5.4rem;
    height: 0.5rem;
    outline: none;
    border: none;
    font-size: 0.32rem;
    color: #333333;
    text-align: right;
}
.addModule{
    width: 6.9rem;
    margin: 0 auto;
    margin-top: 0.25rem;
    font-size: 0.28rem;
    color: #e14040;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    padding-bottom: 1.3rem;
}
.add{
    font-size: 0.28rem;
    color: #e14040;
}
.userInfoBtnModule{
    position: fixed;
    width: 7.5rem;
    height: 1.9rem;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: #fff;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
}
.saveUserValue{
    width: 6rem;
    height: 0.85rem;
    line-height: 0.85rem;
    text-align: center;
    border-radius: 0.42rem;
    font-size: 0.28rem;
    color: #fff;
    background-image: linear-gradient(to right,#e9402d,#e74e44);
}
</style>>
<script>
export default {
  name: "allOrderList",
  data() {
    return {
      maxCount:0,
      bizId:'',
      userData:[],
      userText:[{name:'游玩人1'},{name:'游玩人2'},{name:'游玩人3'},{name:'游玩人4'},]
    };
  },
  components: {},
  created() {
    this.loadUserNum();
  },
  methods: {
    loadUserNum(){
        const {count,bizId} = this.$route.query;
        this.maxCount = count;
        this.bizId = bizId;
        for(let i = 0;i<count;i++){
            var obj = {
                // name:this.userText[i].name,
                name:'',
                mobile:'',
                idCardNo:''
            }
            this.userData.push(obj)
        }
    },
    addUserValue(){
        var userDataLength = this.userData.length;
        if(userDataLength<this.maxCount){
            var obj = {
                name:'',
                mobile:'',
                idCardNo:''
            }
            this.userData.push(obj)  
        }else{
            this.$toast({
            message: '添加游客以达上限',
            position: "center"
          });
          return;
        }
    },
    upUserData(){

        this.request.post('/api/n/trip/addInfo',{
            bizId:this.bizId,
            payOrderNo:this.bizId,
            spayOrderNo:this.bizId,
            infoList:this.userData
        }).then(res=>{
            console.log(res)
            if(res){
                this.$router.push({
                path: "/h/n/upSuccess",
                 query: { },
                });
            }
        })
    },
    moduleName(index){
        return this.userText[index].name
    }
  }
};
</script>